import { Injectable, Inject } from '@angular/core';
import { ApolloError, WatchQueryFetchPolicy } from '@apollo/client';
import chunk from 'lodash-es/chunk';
import {
	map,
	Observable,
	tap,
	Subscription,
	takeUntil,
	Subject,
	BehaviorSubject,
	take,
	retry,
	mergeMap,
	filter,
	ReplaySubject,
	from,
	bufferTime,
	concatMap,
	combineLatest,
	debounceTime,
	startWith
} from 'rxjs';

import { DeviceRelationshipService } from '@shure/cloud/device-management/shared/services';
import {
	apolloErrorContains,
	EventObserver,
	nonRetryableErrorSubStrings,
	SubscriptionManager,
	SubscriptionManagerConfigCreate
} from '@shure/cloud/shared/apollo';
import { InventoryDevice, isDeviceUpdating } from '@shure/cloud/shared/models/devices';
import { UpdateResponse } from '@shure/cloud/shared/models/http';
import { OktaInterfaceService, monitorLoginState } from '@shure/cloud/shared/okta/data-access';
import { APP_ENVIRONMENT, AppEnvironment } from '@shure/cloud/shared/utils/config';
import { ILogger } from '@shure/shared/angular/utils/logging';

import { CloudDeviceApiService } from '../api/cloud-device-api.service';
import { DeviceDiscoveryApiService } from '../api/device-discovery-api.service';
import { DeviceStatusService } from '../api/device-status.service';
import {
	DeviceInventoryEvent,
	DeviceInventoryLoadingProgress,
	InventoryDevicesApiService
} from '../api/inventory-devices-api.service';

import {
	NodeChangeType,
	InventoryDeviceByIdQueryGQL,
	InventoryDeviceSubscriptionGQL,
	InventoryDeviceFragment,
	InventoryDevicesByIdQueryGQL,
	InventoryDevicesByIdQueryOpResult
} from './graphql/generated/cloud-sys-api';
import { mapInventoryDeviceFromSysApi } from './mappers/map-inventory-device';
import { SysApiDeviceInventoryApolloCache } from './sys-api-device-inventory-apollo-cache.service';

@Injectable({ providedIn: 'root' })
export class SysApiInventoryDevicesApiService implements InventoryDevicesApiService {
	public deviceInventory = new BehaviorSubject<InventoryDevice[]>([]);
	public deviceInventoryEvents = new Subject<DeviceInventoryEvent>();
	private deviceInventoryMap = new Map<string, InventoryDevice>();
	private serviceInitialized = false;
	private inventoryLoadingProgress = new BehaviorSubject<DeviceInventoryLoadingProgress>({
		startTime: Date.now(),
		state: 'WaitingToStart',
		discoveredDeviceCount: 0,
		inventoryDeviceCount: 0,
		elapsedTime: 0,
		percentComplete: 0,
		popcornTimerExpired: undefined
	});

	private readonly logger: ILogger;
	private destroy$ = new Subject<void>();

	// A Queue for deviceIds which we need to create watchQueries and Subscriptions for.
	private watchQueryQueue = new ReplaySubject<string>();
	private watchQuerySubscriptions = new Map<string, Subscription>();

	// A set and subject to track how long subscription snapshots take to be received.
	// no real functional benefit to this... only for understanding perfomrance.
	private pendingSubscriptionSnapshotsSet = new Set<string>();
	private pendingSubscriptionSnapshots = new Subject<string[]>();

	private readonly devicesSubscriptionManager = new SubscriptionManager({
		subscriptionType: 'inventory-devices',
		create: (config): Subscription => this.createDeviceSubscription(config),
		retryWaitMs: 10_000,
		maxRetryAttempts: 3
	});

	private addedDevices$ = this.deviceDiscoveryService.discoveryEvents$().pipe(
		filter((event) => event.type === 'deviceAdded'),
		map((event) => event.id),
		bufferTime(100),
		filter((ids) => ids.length > 0),
		takeUntil(this.destroy$)
	);

	private removedDevice$ = this.deviceDiscoveryService.discoveryEvents$().pipe(
		filter((events) => events.type === 'deviceRemoved'),
		map((event) => event.id),
		takeUntil(this.destroy$)
	);

	// Inventory Loading is Successful when deviceDiscovery has completed
	// and the either the discovered device count is 0, or the discoveredDeviceCount
	// matches the inventory device count.
	private inventoryLoadingSuccess$ = combineLatest([
		this.deviceDiscoveryService.discoveryComplete$.pipe(startWith(false)),
		this.deviceDiscoveryService.discoveredDevicesCountAfterDiscovery$(),
		this.getInventoryDevicesCount$()
	]).pipe(
		filter(([discoveryComplete, _discoveredDeviceCount, _inventoryCount]) => discoveryComplete),
		tap(([_discoveryComplete, discoveredDeviceCount, inventoryCount]) => {
			this.setAndEmitLoadingProgress('QueryingInventoryData', discoveredDeviceCount, inventoryCount, false);
			if (discoveredDeviceCount === 0 || discoveredDeviceCount === inventoryCount) {
				this.setAndEmitLoadingProgress('Completed', discoveredDeviceCount, inventoryCount, false);
			}
		}),
		filter(
			([_discoveryComplete, discoveredDeviceCount, inventoryCount]) =>
				discoveredDeviceCount === 0 || discoveredDeviceCount === inventoryCount
		),
		map(() => true),
		take(1),
		takeUntil(this.destroy$)
	);

	// Inventory Loading is a timeout when deviceDiscovery has completed
	// and we stop receiving changes to the discoveredDeviceCount and the inventoryDeviceCount
	// for a period of time (i.e. the popcorn timer).
	private inventoryLoadingTimeout$ = combineLatest([
		this.deviceDiscoveryService.discoveryComplete$.pipe(startWith(false)),
		this.deviceDiscoveryService.discoveredDevicesCountAfterDiscovery$(),
		this.getInventoryDevicesCount$()
	]).pipe(
		filter(([discoveryComplete, _discoveredDeviceCount, _inventoryCount]) => discoveryComplete),
		debounceTime(this.appEnv.cdmPerformance?.inventoryLoadingPopcornTimeout ?? 15_000),
		tap(([_discoveryComplete, discoveredDeviceCount, inventoryCount]) =>
			this.setAndEmitLoadingProgress('Completed', discoveredDeviceCount, inventoryCount, true)
		),
		map(() => true),
		take(1),
		takeUntil(this.destroy$)
	);

	// Inventory Loading is done when either there was a success or timeout
	private inventoryLoadingDone$ = combineLatest([
		this.inventoryLoadingSuccess$.pipe(startWith(false)),
		this.inventoryLoadingTimeout$.pipe(startWith(false))
	]).pipe(
		filter(([success, timeout]) => success || timeout),
		take(1),
		takeUntil(this.destroy$)
	);

	constructor(
		logger: ILogger,
		private readonly cloudDeviceService: CloudDeviceApiService,
		private readonly inventoryDeviceSubscriptionGQL: InventoryDeviceSubscriptionGQL,
		private readonly inventoryDeviceByIdQueryGQL: InventoryDeviceByIdQueryGQL,
		private readonly inventoryDevicesByIdQueryGQL: InventoryDevicesByIdQueryGQL,
		private readonly deviceDiscoveryService: DeviceDiscoveryApiService,
		private readonly deviceStatusService: DeviceStatusService,
		private readonly oktaService: OktaInterfaceService,
		private readonly deviceRelationshipService: DeviceRelationshipService,
		@Inject(APP_ENVIRONMENT) private readonly appEnv: AppEnvironment,
		private readonly apolloCache: SysApiDeviceInventoryApolloCache
	) {
		this.logger = logger.createScopedLogger('CloudInventoryDeviceService');

		monitorLoginState(this.oktaService, {
			onLogIn: this.initService.bind(this),
			onLogOut: this.suspendService.bind(this)
		});
	}

	public getInventoryDevicesCount$(): Observable<number> {
		return this.getInventoryDevices$().pipe(map((devices) => devices.length));
	}

	public getInventoryDevices$(): Observable<InventoryDevice[]> {
		return this.deviceInventory.asObservable();
	}

	public getUpdatingInventoryDevices$(): Observable<InventoryDevice[]> {
		return this.getInventoryDevices$().pipe(map((devices) => devices.filter(isDeviceUpdating)));
	}

	public getInventoryDeviceEvents$(): Observable<DeviceInventoryEvent> {
		return this.deviceInventoryEvents.asObservable();
	}

	public getInventoryDevice$(deviceId: string, fetchPolicy: WatchQueryFetchPolicy): Observable<InventoryDevice> {
		return this.getInventoryDeviceFragment$(deviceId, fetchPolicy).pipe(
			map(({ inventoryDevice }) => inventoryDevice)
		);
	}

	public deviceInventoryLoadingProgress$(): Observable<DeviceInventoryLoadingProgress> {
		return this.inventoryLoadingProgress.asObservable();
	}

	public setIdentify(deviceId: string, identify: boolean): Observable<UpdateResponse<void, string>> {
		this.logger.trace('setIdentify()', 'Setting identify', { deviceId, identify });
		return this.cloudDeviceService.setIdentify(deviceId, identify);
	}

	private initService(): void {
		// this check is a preventative meausre to ensure that if we received two "true" transitions
		// in a row, this service won't re-discover devices again.
		if (this.serviceInitialized === true) {
			this.logger.error('initService', 'initService called when already initialized');
			return;
		}

		this.logger.information('initService', 'user logged in, initializating service');
		this.destroy$ = new Subject();
		this.setAndEmitLoadingProgress('DiscoveringDevices');
		this.buildInventory();

		this.deviceDiscoveryService.discoveryComplete$
			.pipe(
				filter((discoverComplete) => discoverComplete),
				take(1),
				takeUntil(this.destroy$)
			)
			.subscribe();
	}

	private suspendService(): void {
		this.serviceInitialized = false;
		this.logger.information('suspendService', 'user logged out, suspending service');
		this.destroy$.next();
		this.destroy$.complete();
		this.devicesSubscriptionManager.deregisterAll();
	}

	private deviceCountPerNodesQuery(deviceCount: number): number {
		const concurrency = this.appEnv.cdmPerformance?.nodesQueryConcurrency ?? 100;
		const maxNodesPerQuery = this.appEnv.cdmPerformance?.nodesQueryMaxNodeIDs ?? 25;
		if (deviceCount <= concurrency) return 1;
		return Math.min(maxNodesPerQuery, Math.ceil(deviceCount / concurrency));
	}

	private buildInventory(): void {
		// Once we're done loading the inventory, we can setup the watchQueries and subscriptions for any queued device, and for
		this.inventoryLoadingDone$.subscribe(() => {
			this.setupLongRunningWatchQueries();
		});

		this.handleDeviceAddedEvents();
		this.handleDeviceRemovedEvents();
		this.monitorSubscriptionSnapshots();
	}

	private handleDeviceAddedEvents(): void {
		this.addedDevices$
			.pipe(
				map((ids) => chunk(ids, this.deviceCountPerNodesQuery(ids.length))),
				concatMap((chunks) => from(chunks)),
				mergeMap((chunk) => {
					return this.getInventoryDevicesFragment$(chunk, 'no-cache').pipe(
						retry({ count: 2, delay: 3000 }),
						tap((inventoryDeviceFragments) => {
							inventoryDeviceFragments.forEach((inventoryDeviceFragment) => {
								if (inventoryDeviceFragment === null || !('isDevice' in inventoryDeviceFragment))
									return;

								// seed apollo cache
								this.apolloCache.seedEntry(inventoryDeviceFragment);

								const inventoryDevice = mapInventoryDeviceFromSysApi(
									inventoryDeviceFragment,
									this.deviceStatusService
								);

								this.deviceRelationshipService.registerProxiedDevices(inventoryDevice);
								this.updateAndEmitInventoryChange('added', inventoryDevice);

								// Queue the device for watchQuery and subscription creation.
								this.watchQueryQueue.next(inventoryDevice.id);
							});
						}),
						take(1)
					);
				})
			)
			.subscribe();
	}

	private handleDeviceRemovedEvents(): void {
		this.removedDevice$
			.pipe(
				tap((deviceToRemove) => {
					const mapEntry = this.deviceInventoryMap.get(deviceToRemove);

					if (mapEntry !== undefined) {
						this.updateAndEmitInventoryChange('removed', mapEntry);
					}

					this.devicesSubscriptionManager.deregister(deviceToRemove);
					const subscription = this.watchQuerySubscriptions.get(deviceToRemove);
					if (subscription !== undefined) {
						subscription.unsubscribe();
						this.watchQuerySubscriptions.delete(deviceToRemove);
					}

					this.deviceRelationshipService.removeDevice(deviceToRemove);
					this.apolloCache.removeEntry(deviceToRemove);
				})
			)
			.subscribe();
	}

	private setupLongRunningWatchQueries(): void {
		const concurrency = 500;
		this.watchQueryQueue
			.asObservable()
			.pipe(
				mergeMap((deviceId: string) => {
					return new Observable((observer) => {
						const watchQuerySubscription = this.getInventoryDeviceFragment$(deviceId, 'cache-only')
							.pipe(
								tap(({ inventoryDevice }) => {
									this.trackSubscriptionSnapshot(deviceId, 'waitingFor');
									this.devicesSubscriptionManager.registerWithObserver(
										deviceId,
										new EventObserver(observer, 5000)
									);
									this.deviceRelationshipService.registerProxiedDevices(inventoryDevice);
									this.updateAndEmitInventoryChange('updated', inventoryDevice);
								}),
								takeUntil(this.destroy$)
							)
							.subscribe();
						this.watchQuerySubscriptions.set(deviceId, watchQuerySubscription);
					}).pipe(tap({ complete: () => this.trackSubscriptionSnapshot(deviceId, 'received') }));
				}, concurrency),
				takeUntil(this.destroy$)
			)
			.subscribe();
	}

	private updateAndEmitInventoryChange(type: DeviceInventoryEvent['type'], device: InventoryDevice): void {
		if (['added', 'updated'].includes(type)) {
			// If there was a timeout during inventory loading, this device may not be in the map.
			// In this case, we need to treat 'updated' like 'added' so ag-grid will add the device.
			if (type === 'updated' && !this.deviceInventoryMap.has(device.id)) {
				type = 'added';
			}
			this.deviceInventoryMap.set(device.id, device);
		} else {
			this.deviceInventoryMap.delete(device.id);
		}
		this.deviceInventory.next([...this.deviceInventoryMap.values()]);
		this.deviceInventoryEvents.next({ type, device });
	}

	// To track subscription snapshots, we insert the deviceID when we start waiting, and
	// remove when the snapshot is received.  when the set becomes empty the first time,
	// we're done.
	private trackSubscriptionSnapshot(id: string, type: 'waitingFor' | 'received'): void {
		if (type === 'waitingFor') {
			this.pendingSubscriptionSnapshotsSet.add(id);
		} else {
			this.pendingSubscriptionSnapshotsSet.delete(id);
		}
		this.pendingSubscriptionSnapshots.next(Array.from(this.pendingSubscriptionSnapshotsSet));
	}

	private monitorSubscriptionSnapshots(): void {
		// we're done receiving snapshots when the set is empty for the first time.
		// we only care about the first emission.
		this.pendingSubscriptionSnapshots
			.asObservable()
			.pipe(
				filter((ids) => ids.length === 0),
				take(1),
				takeUntil(this.destroy$)
			)
			.subscribe(() => this.saveSubscriptionSnapshotTime());
	}

	private getInventoryDeviceFragment$(
		deviceId: string,
		fetchPolicy: WatchQueryFetchPolicy
	): Observable<{ inventoryDeviceFragment: InventoryDeviceFragment; inventoryDevice: InventoryDevice }> {
		return this.inventoryDeviceByIdQueryGQL
			.watch(
				{
					nodeId: deviceId,
					requestLicenseV3: this.appEnv.cdmFeatureFlags?.licenseV3 ?? true
				},
				{
					errorPolicy: 'ignore',
					fetchPolicy,
					returnPartialData: true
				}
			)
			.valueChanges.pipe(
				filter((query) => query.data !== null && 'node' in query.data && 'isDevice' in query.data.node),
				map((query) => <InventoryDeviceFragment>query.data.node),
				map((device) => {
					return {
						inventoryDeviceFragment: device,
						inventoryDevice: mapInventoryDeviceFromSysApi(device, this.deviceStatusService)
					};
				})
			);
	}

	private getInventoryDevicesFragment$(
		deviceIds: string[],
		fetchPolicy: WatchQueryFetchPolicy
	): Observable<InventoryDevicesByIdQueryOpResult['nodes']> {
		return this.inventoryDevicesByIdQueryGQL
			.watch(
				{
					nodeIds: deviceIds,
					requestLicenseV3: this.appEnv.cdmFeatureFlags?.licenseV3 ?? true
				},
				{
					errorPolicy: 'ignore',
					fetchPolicy,
					returnPartialData: true
				}
			)
			.valueChanges.pipe(
				filter((query) => query.data !== null),
				map((query) => query.data.nodes)
			);
	}

	private createDeviceSubscription({
		id,
		retryCallback,
		eventObserver
	}: SubscriptionManagerConfigCreate): Subscription {
		const subscriptionTypes: NodeChangeType[] = [
			NodeChangeType.DeviceAudioMute,
			NodeChangeType.DeviceAvailablePackages,
			NodeChangeType.DeviceBatteryLevel,
			NodeChangeType.DeviceControlNetwork,
			NodeChangeType.DeviceIdentify,
			NodeChangeType.DeviceLicenseV3,
			NodeChangeType.DeviceMicStatus,
			NodeChangeType.DeviceName,
			NodeChangeType.DeviceProxiedDevices,
			NodeChangeType.DeviceUpdateProgress,
			NodeChangeType.DeviceRoom,
			NodeChangeType.DeviceTags
		];

		return this.inventoryDeviceSubscriptionGQL
			.subscribe(
				{
					id,
					types: subscriptionTypes,
					requestLicenseV3: this.appEnv.cdmFeatureFlags?.licenseV3 ?? true
				},
				{
					errorPolicy: 'ignore',
					fetchPolicy: 'network-only' //  fetch from server, update apollo cache.
				}
			)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: () => {
					if (eventObserver) {
						eventObserver.eventReceived();
					}
				},
				// in case the BE closes the WS, invoke the retry logic.
				complete: () => {
					this.logger.error(
						'inventoryDeviceSubscriptionGQL',
						'subscription completed. Will retry.',
						JSON.stringify({ nodeId: id })
					);
					retryCallback();
				},
				error: (error: ApolloError) => {
					if (apolloErrorContains(error, nonRetryableErrorSubStrings)) return;
					this.logger.error(
						'inventoryDeviceSubscriptionGQL',
						'Encountered retryable error',
						JSON.stringify({ id, error }, null, 3)
					);
					retryCallback();
				}
			});
	}

	private setAndEmitLoadingProgress(
		state: DeviceInventoryLoadingProgress['state'],
		discoveredDeviceCount?: number,
		inventoryDeviceCount?: number,
		timeout?: boolean
	): void {
		const currentState = this.inventoryLoadingProgress.getValue();
		const newState = {
			...currentState,
			state: state,
			discoveredDeviceCount: discoveredDeviceCount ?? 0,
			inventoryDeviceCount: inventoryDeviceCount ?? 0,
			elapsedTime: (Date.now() - currentState.startTime) / 1000,
			percentComplete:
				discoveredDeviceCount === undefined || discoveredDeviceCount === 0
					? 0
					: ((inventoryDeviceCount ?? 0) / discoveredDeviceCount) * 100,
			popcornTimerExpired: timeout
		};

		// only save state local storage when state has changed. No need to perform potenitall hundreds or thousands of
		// writes to localstorage when device counts are changing.
		if (currentState.state !== newState.state) {
			this.saveLoadingProgress(newState);
		}

		this.inventoryLoadingProgress.next(newState);
	}

	private saveLoadingProgress(progress: DeviceInventoryLoadingProgress): void {
		if (!this.appEnv.production) {
			// The only interesting values to store are when discovery is complete
			// and when the inventory is complete.
			let lsKeySuffix = '';
			let numDevices = 0;
			switch (progress.state) {
				case 'QueryingInventoryData':
					lsKeySuffix = 'deviceDiscovery';
					numDevices = progress.discoveredDeviceCount;
					break;
				case 'Completed':
					lsKeySuffix = 'deviceInventory';
					numDevices = progress.inventoryDeviceCount;
					break;
				default:
					return;
			}

			const formattedTime = progress.elapsedTime.toLocaleString('en-US', { minimumFractionDigits: 3 });
			sessionStorage.setItem(
				`shure.${lsKeySuffix}`,
				`${formattedTime} sec${
					progress.popcornTimerExpired ? '(timeout)' : ''
				}, ${numDevices.toString()} devices`
			);
		}
	}
	private saveSubscriptionSnapshotTime(): void {
		if (!this.appEnv.production) {
			const currentState = this.inventoryLoadingProgress.getValue();
			const elapsedTime = (Date.now() - currentState.startTime) / 1000;
			const formattedTime = elapsedTime.toLocaleString('en-US', { minimumFractionDigits: 3 });
			sessionStorage.setItem('shure.subscriptionSnapshots', `${formattedTime} sec`);
		}
	}
}
